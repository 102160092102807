import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from '../components/layout';

export const pageQuery = graphql`
  query {
    allWpGallery {
      nodes {
        title
        slug
        id
        featuredImage {
          node {
            localFile {
              id
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED,
                  width: 500,
                  height: 400
                  placeholder: BLURRED,
                  formats: [AUTO, WEBP, AVIF],
                )
              }
            }
          }
        }
      }
    }
  }
`;

const Foto = ({ data }) => {
  const gallery = data.allWpGallery.nodes;

  return (
    <Layout>
      <h1>Fotogalerie</h1>
      <div className="gallery">
        {gallery.map((item, index) => {
          let image = item.featuredImage?.node;

          if(!image) {
            return false
          }

          return (
            <Link to={`${item.slug}`} key={`${image.localFile.id}index-${index}`}>
              <div>
                <GatsbyImage image={getImage(image.localFile)} alt={item.title} />
              </div>
              <span className="gallery__title">{item.title}</span>
            </Link>
          );
        })}
      </div>
    </Layout>
  );
};

export default Foto;
